<template>
  <div
    class="page page--main js-scrollToMap openMap--half openMap--full"
    :class="[{'messageCenterOpen' : this.$store.state.messageCenter.status}]">
    <div class="content">
      <Header
        :data="true"
        :title="this.$route.matched[1].props.default.header"
        :subtitle="null"
        :type="'booking'"
      />

      <div class="content__inner">
        <div class="box box--bookingBlock">
          <div class="box__inner">
            <div class="bookingBlock" :class="'bookingBlock--' + this.$route.matched[1].components.default.name">
              <div class="bookingBlock__header">
                <div class="bookingBlock__header-top">
                  <div class="bookingBlock__header-inner">
                    <div class="bookingBlock__breadcrumbs">
                      <div class="breadcrumbs">
                        <div class="breadcrumbs__item">
                          <router-link class="breadcrumbs__item--link" to="/map-search">
                            <span class="breadcrumbs__text">Map</span>
                          </router-link>
                        </div>
                        <div class="breadcrumbs__item">
                          <router-link class="breadcrumbs__item--link" to="/search-results">
                            <span class="breadcrumbs__text">Search Results</span>
                          </router-link>
                        </div>
                        <div class="breadcrumbs__item">
                          <div>
                            <span class="breadcrumbs__text">Booking</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bookingBlock__info">
                      <div class="bookingBlock__info-left">
                        <div class="bookingBlock__info-title">Booking:</div>
                      </div>
                      <div class="bookingBlock__info-right">
                        <div class="bookingBlock__info-list">
                          <div class="bookingBlock__info-list-item bookingBlock__info-list-item--route">
                            <div class="bookingBlock__info-list-icon">
                              <SvgIcon name="placeholder"/>
                            </div>
                            <div class="bookingBlock__info-list-text">
                              <span class="text text--departure">{{bookingData.departure}}</span>
                              <span class="arrow">
                                <SvgIcon name="long-left-arrow"/>
                              </span>
                              <span class="text text--arrival">{{bookingData.arrival}}</span>
                            </div>
                          </div>
                          <div class="bookingBlock__info-list-item bookingBlock__info-list-item--way">
                            <div class="bookingBlock__info-list-icon">
                              <SvgIcon name="airplane"/>
                            </div>
                            <div class="bookingBlock__info-list-text">{{bookingData.returnDate !== null ? 'Round-trip' : 'One way'}}</div>
                          </div>
                          <div class="bookingBlock__info-list-item bookingBlock__info-list-item--date">
                            <div class="bookingBlock__info-list-icon">
                              <SvgIcon name="calendar"/>
                            </div>
                            <div class="bookingBlock__info-list-text">
                              <span class="text text--desktop" v-if="!bookingData.returnDate">
                                {{months[new Date(bookingData.departureDate).getMonth()]}} {{new Date(bookingData.departureDate).getDate()}}, {{new Date(bookingData.departureDate).getFullYear()}}
                              </span>
                              <span class="text text--desktop" v-else>
                                {{months[new Date(bookingData.departureDate).getMonth()]}} {{new Date(bookingData.departureDate).getDate()}}, {{new Date(bookingData.departureDate).getFullYear()}}
                                - {{months[new Date(bookingData.returnDate).getMonth()]}} {{new Date(bookingData.returnDate).getDate()}}, {{new Date(bookingData.returnDate).getFullYear()}}
                              </span>
                              <span class="text text--mobile" v-if="!bookingData.returnDate">{{moment(bookingData.departureDate).format("MMM D, YYYY")}}</span>
                              <span class="text text--mobile" v-else>{{moment(bookingData.departureDate).format("MMM D, YYYY")}} - {{moment(bookingData.returnDate).format("MMM D, YYYY")}}</span>
                            </div>
                          </div>
                          <div class="bookingBlock__info-list-item bookingBlock__info-list-item--count">
                            <div class="bookingBlock__info-list-icon">
                              <SvgIcon name="user"/>
                            </div>
                            <div class="bookingBlock__info-list-text">
                              <span class="number">{{bookingData.pax}}</span>
                            </div>
                          </div>
                          <div class="bookingBlock__info-list-item bookingBlock__info-list-item--type">
                            <div class="bookingBlock__info-list-icon">
                              <SvgIcon name="charter-flight"/>
                            </div>
                            <div v-if="bookingData.bookingType === 2" class="mapBlock__info-list-text">Charter Aircraft</div>
                            <div v-else-if="bookingData.bookingType === 4" class="mapBlock__info-list-text">Charter Seat</div>
                            <div v-else-if="bookingData.bookingType === 6" class="mapBlock__info-list-text">Charter Aircraft, Charter Seat</div>
                            <div v-else-if="bookingData.bookingType === 10" class="mapBlock__info-list-text">Charter Aircraft, Commercial Seat</div>
                            <div v-else-if="bookingData.bookingType === 12" class="mapBlock__info-list-text">Charter Seat, Commercial Seat</div>
                            <div v-else-if="bookingData.bookingType === 14" class="mapBlock__info-list-text">Charter Aircraft, Charter Seat, Commercial Seat</div>
                            <div v-else-if="!bookingData.bookingType" class="mapBlock__info-list-text">Commercial Seat</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bookingBlock__header-bottom">
                  <div class="bookingBlock__nav js-bookingNav">
                    <router-link
                      v-for="(item, index) of nav"
                      active-class="active"
                      class="bookingBlock__nav-item js-bookingNav-item"
                      :to="item.href"
                      :key="index">
                      {{item.title}}
                      <span v-if="item.subtitle">{{item.subtitle}}</span>
                    </router-link>
                  </div>
                </div>
              </div>
              <router-view/>
            </div>
          </div>
        </div>
      </div>
      <MessageCenter/>
      <Footer :type="null"/>
    </div>
    <ThanksPopup/>
    <FlyGreenPopup/>
  </div>
</template>

<script>
  import moment from 'moment';
  import Header from '@/components/Header/Header'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Footer from '@/components/Footer/Footer'
  import MessageCenter from '@/components/common/MessageCenter/MessageCenter'
  import ThanksPopup from '@/components/common/modals/ThanksPopup';
  import {months} from '@/js/utils/date'
  import FlyGreenPopup from '@/components/common/modals/FlyGreenPopup'

  import '@/views/booking/Booking.styl'
  import '@/views/booking/Flight/Flight.styl'
  import '@/views/booking/Payment/Payment.styl'
  import '@/views/booking/Travelers/Travelers.styl'
  import '@/components/AircraftDetail/AircraftDetail.styl'
  import '@/components/common/Breadcrumbs/Breadcrumbs.styl'

  export default {
    name: 'booking-layout',
    data: () => ({
      nav: [
        {
          title: 'Flights',
          href: 'flights',
        },
        {
          title: 'Aircraft',
          href: 'aircraft',
        },
        {
          title: 'Travelers',
          href: 'travelers',
        },
        {
          title: 'Terms',
          href: 'terms',
        },
        {
          title: 'FLY',
          subtitle: 'Green',
          href: 'fly-green',
        },
        {
          title: 'Confirmation',
          href: 'payment',
        },
        // {
        //   title: 'Confirmation',
        //   href: 'confirmation',
        // },
      ],
      months: months,
    }),
    computed: {
      bookingData () {
        return this.$store.state.bookingFromSearch.fullBookingItem
      },
      terms () {
        return this.$store.state.bookingFromSearch.terms
      },
    },
    updated () {
      this.changeActiveLink()
    },
    mounted () {
      this.changeActiveLink()
    },
    methods: {
      moment,
      changeActiveLink () {
        let activeLink = null

        document.querySelectorAll('.bookingBlock__nav-item').forEach((item, index) => {
          if (item.classList.contains('active')) {
            activeLink = index
          }
        })

        document.querySelectorAll('.bookingBlock__nav-item').forEach((item, index) => {
          if (!item.classList.contains('active') && index < activeLink) {
            item.classList.add('prev')
          } else {
            item.classList.remove('prev')
          }
        })
      },
    },
    components: {
      Header,
      SvgIcon,
      Footer,
      MessageCenter,
      ThanksPopup,
      FlyGreenPopup,
    },
  }
</script>
